<template>
  <div>
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12" md="8">
          <h3>Exception List</h3>
        </b-col>
        <b-col cols="12" md="4">
          <b-button variant="primary" class="float-right" @click="addException">
            Add Exception
          </b-button>
        </b-col>
      </b-row>
      <table class="table table-bordered p-2">
        <thead>
          <tr>
            <th width="5%">SL</th>
            <th width="15%">Employee Name</th>
            <th width="10%">Date</th>
            <th width="70%">Reasions</th>
          </tr>
        </thead>
        <tbody id="rows">
          <tr v-for="(exception, index) in exceptions" :key="index">
            <td>
              <span>{{ from + index }}</span>
            </td>
            <td>
              <p>{{ exception.name }}</p>
            </td>
            <td>
              <p>{{ exception.date }}</p>
            </td>
            <td>
              <p
                class="custom-border"
                v-for="(reason, index) in exception.reasons"
                :key="index"
              >
                {{ reason.reason }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BCard } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
  },
  data() {
    return {
      exceptions: [],
      from: 1,
    };
  },
  created() {
    this.getExceptions();
  },
  methods: {
    getExceptions() {
      this.$store
        .dispatch("roster/getExceptions")
        .then((response) => {
          this.exceptions = response.data.data;
          // console.log(this.exceptions);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addException() {
      this.$router.push({ name: "exception-create" });
    },
  },
};
</script>

<style scoped>
th,
td {
  border-color: #b9b6c1 !important;
}
.custom-border {
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #b9b6c1;
}
table td {
  vertical-align: middle !important;
  padding: 0 !important;
}
table td span {
  padding: 0.5rem !important;
}
table td p {
  margin: 0;
  padding: 0.5rem !important;
}
</style>
